import { useCallback } from 'react'
import { getApiUrl } from './getApiUrl'
import { FetchOptions } from './types'
import { useFetchHandler } from './useFetchHandler'
import { usePayload } from './usePayload'
import { useProcessFetch } from './useProcessFetch'

export const usePost = <TReturn>(options?: FetchOptions) => {
  const { fetch, isFetching } = useFetchHandler<TReturn>(options)

  const getPayload = usePayload('POST')
  const processFetch = useProcessFetch()

  const post = useCallback(
    async (url: string, body) => {
      return fetch(() => processFetch(getApiUrl(url, options?.fetchOptions?.byPassCombine), getPayload(body)))
    },
    [fetch, getPayload, processFetch, options?.fetchOptions?.byPassCombine],
  )

  return {
    post,
    isPosting: isFetching,
  }
}
