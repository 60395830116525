import React, { FC } from 'react'
import { Flex } from 'mui-blox'
import { useScript } from '~brix/connectors/context/useScript'
import { useQueryParams } from '../routing'
import { sciensio } from '../../config'
import { FramedPage, PageTitle, PageTitleProvider, useBreakpoint } from '~layout'
import { buildQuery } from '~core/util/buildQuery'
import PhoneFrame from './decorators/PhoneFrame'
import { useFlags } from 'flagsmith/react'
import { config } from '~config'

export const MessengerClient: FC = () => {
  const { event } = useQueryParams()
  const flags = useFlags(['cloud-function_messenger-api'])
  const cloudApiFeatureFlag = flags['cloud-function_messenger-api'].enabled

  const isXs = useBreakpoint('sm').down

  const baseUrl = cloudApiFeatureFlag
    ? `${config.firebase.exconPlaceMessengerApi}/api/v2/messenger/event/${event}`
    : `${sciensio.apiUrl}/api/messenger/${event}`

  const scriptUrl = buildQuery(baseUrl, {
    variant: 'embedded',
    height: '100%',
    width: '100%',
  })

  useScript(scriptUrl)

  return (
    <PageTitleProvider>
      <FramedPage disableHeader disableLeftBar={isXs}>
        <PageTitle title='Messenger'>
          {!isXs && (
            <Flex flexColumn alignItems='center' justifyContent='center' p={1}>
              <PhoneFrame frame='phone' color='black' style={{ width: 350, maxHeight: 750, overflow: 'scrollbar' }}>
                <div id='42chat-messenger' style={{ height: '100%', width: '100%' }} />
              </PhoneFrame>
            </Flex>
          )}
          {isXs && <div id='42chat-messenger' style={{ height: '100%', width: '100%' }} />}
        </PageTitle>
      </FramedPage>
    </PageTitleProvider>
  )
}
