import { useCallback } from 'react'
import { usePlatformRequest } from './usePlatformRequest'

export const useGetExconSettings = (exconId: string) => {
  const { request, isRequesting } = usePlatformRequest('https-getExconSettings', 'GET', exconId)

  const getExconSettings = useCallback(async () => {
    try {
      const response = await request()

      return response
    } catch (error) {
      console.error('*** Error fetching excon settings', error)
    }
  }, [request])

  return {
    getExconSettings,
    isGetting: isRequesting,
  }
}
