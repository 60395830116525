import React from 'react'
import PropTypes from 'prop-types'
import { Flex } from 'mui-blox'
import { fromJS } from 'immutable'
import { makeStyles } from '@mui/styles'
import Intro from '../common/Intro'
import { Conditional, BottomPullList } from '~controls'
import NewConversationRow from '../../conversations/NewConversationRow'
import { useHighZoomDetection } from '../hooks/useHighZoomDetection'

const Conversation = ({ conversation, isLoaded }) => {
  const classes = useStyles()

  const { isVeryHighZoom } = useHighZoomDetection(4)

  const renderMessage = index => {
    const logEntry = conversation[index]
    const next = conversation[index + 1]
    const prev = conversation[index - 1]

    return (
      <NewConversationRow log={fromJS(logEntry)} next={fromJS(next)} prev={fromJS(prev)} highlightSupport={false} />
    )
  }

  return (
    <Flex
      flexColumn
      className={classes.root}
      position='relative'
      p={1}
      pt={8}
      height={isVeryHighZoom ? '100%' : 'auto'}
      minHeight={isVeryHighZoom ? '100%' : 0}
    >
      <Conditional
        conditions={{
          conversation: conversation.length > 0,
          noConversation: conversation.length === 0,
        }}
      >
        {{
          conversation: () => <BottomPullList rowCount={conversation.length} onRenderRow={renderMessage} />,
          noConversation: () => <Intro loading={!isLoaded} />,
        }}
      </Conditional>
    </Flex>
  )
}

Conversation.propTypes = {
  conversation: PropTypes.array,
  isLoaded: PropTypes.bool.isRequired,
}
Conversation.defaultProps = {
  conversation: [],
}

const useStyles = makeStyles(({ palette }) => ({
  root: {
    backgroundColor: palette.background.default,
  },
}))

export default Conversation
