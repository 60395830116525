import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import { useEventBranding } from '~brix/connectors/context/useEventBranding'
import SendMessage from '../../conversations/SendMessage'
import { useHighZoomDetection } from '../hooks/useHighZoomDetection'

const ConversationInput = ({ onSendMessage }) => {
  const { isVeryHighZoom } = useHighZoomDetection(4)

  const { branding } = useEventBranding()
  const classes = useStyles({ isVeryHighZoom })

  return (
    <div className={classes.root}>
      <SendMessage
        onSendMessage={message => onSendMessage(message)}
        placeholder={branding?.display?.inputPlaceholder || 'Message'}
        fullWidth
      />
    </div>
  )
}

ConversationInput.propTypes = {
  onSendMessage: PropTypes.func.isRequired,
}

const useStyles = makeStyles(theme => ({
  root: props => ({
    borderBottom: `1px solid ${theme.palette.secondary.light}33`,
    backgroundColor: theme.palette.background.default,
    padding: props.isVeryHighZoom ? theme.spacing(0.5) : theme.spacing(1),
  }),
}))

export default ConversationInput
