import { Map, fromJS } from 'immutable'
import { useGetExconSettings } from '../../../../app/react/chatPlatform'
import useSWR from 'swr'
import { useGet } from '../fetch/useGet'
import { useFlags } from 'flagsmith/react'

export const useExconSettings = (exconId, settings = Map()) => {
  const flags = useFlags(['cloud-function_settings-api'])
  const cloudApiFeatureFlag = flags['cloud-function_settings-api'].enabled

  const { get } = useGet({ initialFetching: true })
  const { getExconSettings, isGetting } = useGetExconSettings(exconId)

  const { data } = useSWR(
    `/excon/settings/${exconId}`,
    async url => {
      if (exconId === '42') {
        return settings
      }

      if (cloudApiFeatureFlag) {
        const result = await getExconSettings(exconId)
        return fromJS(result)
      }

      const result = await get(url)
      return fromJS(result)
    },
    { revalidateOnFocus: false },
  )

  return { settings: data, isFetching: data === undefined || isGetting }
}
