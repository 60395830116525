import React, { FC, useRef, useState, useEffect } from 'react'
import { Portal } from '@mui/material'
import { MessengerButton } from './MessengerButton'
import { MessengerPopper } from './MessengerPopper'
import { announce } from '@react-aria/live-announcer'

export interface MessengerFloaterProps {
  id: string
  eventId: string
  onOpen?: (open: boolean) => void
  ButtonStyle?: Record<string, unknown>
  MessengerStyle?: Record<string, unknown>
  omitClosedState?: boolean
}

export const MessengerFloater: FC<MessengerFloaterProps> = ({
  id,
  eventId,
  onOpen,
  ButtonStyle,
  MessengerStyle,
  omitClosedState,
}) => {
  const buttonRef = useRef<HTMLDivElement>(null)

  const [open, setOpen] = useState(false)
  const handleButtonClick = () => {
    setOpen(curr => {
      const open = !curr

      onOpen && onOpen(open)

      // const height = open ? '700px' : '56px'
      // document.body.style.height = height
      return open
    })
  }

  useEffect(() => {
    if (open) {
      announce('42chat Chatbot opened', 'assertive')
      const header = document.getElementById('messenger-header')

      header?.focus()
    } else {
      announce('42chat Chatbot closed', 'assertive')
      const toggleBtn = document.getElementById('avatar-button')

      toggleBtn?.focus()
    }
  }, [open])

  return (
    <Portal>
      <MessengerButton
        style={ButtonStyle}
        id={id}
        onClick={handleButtonClick}
        ref={buttonRef}
        open={open}
        omitClosedState={omitClosedState}
      />
      {open && (
        <div
          id='messenger-panel'
          role='dialog'
          aria-labelledby='messenger-botname'
          aria-live='assertive'
          aria-atomic='true'
        >
          <MessengerPopper
            style={MessengerStyle}
            id={id}
            open={open}
            eventId={eventId}
            anchorEl={buttonRef.current}
            onClose={handleButtonClick}
          />
        </div>
      )}
    </Portal>
  )
}
