import { useCallback } from 'react'
import { usePlatformRequest } from './usePlatformRequest'

export const useGetEventSettings = (eventId: string, key: string) => {
  const { request, isRequesting } = usePlatformRequest('https-getEventSettings', 'GET', `${eventId}/${key}`)

  const getEventSettings = useCallback(async () => {
    try {
      const response = await request()

      return response
    } catch (error) {
      console.error('*** Error fetching excon settings', error)
    }
  }, [request])

  return {
    getEventSettings,
    isGetting: isRequesting,
  }
}
