export const EXCON_KEYS = {
  adminSupportMenu: 'adminSupportMenu',
  attendeeList: 'attendeeList',
  badge: 'badge',
  byod: 'byod',
  byodStatus: 'byodStatus',
  calendarAdmin: 'calendarAdmin',
  calendarView: 'calendarView',
  contentForm: 'contentForm',
  customerAdmin: 'customerAdmin',
  displayContent: 'displayContent',
  eventAppMenu: 'eventAppMenu',
  eventFeedback: 'eventFeedback',
  eventFeedbackAdmin: 'eventFeedbackAdmin',
  exhibitorSearch: 'exhibitorSearch',
  externalLink: 'externalLink',
  fileUploadTool: 'fileUploadTool',
  form: 'form',
  glossary: 'glossary',
  healthScreening: 'healthScreening',
  healthScreeningManager: 'healthScreeningManager',
  interactiveForm: 'interactiveForm',
  interactiveFormAdmin: 'interactiveFormAdmin',
  inventory: 'inventory',
  itinerary: 'itinerary',
  listForm: 'listForm',
  lsd: 'lsd',
  manageProfile: 'manageProfile',
  meetings: 'meetings',
  notifications: 'notifications',
  oshHospitals: 'oshHospitals',
  oshPlans: 'oshPlans',
  oshProviders: 'oshProviders',
  partsInventory: 'partsInventory',
  poll: 'poll',
  pollAdmin: 'pollAdmin',
  profileMatch: 'profileMatch',
  quote: 'quote',
  roster: 'roster',
  schedule: 'schedule',
  sendDiscoveryNotifications: 'sendDiscoveryNotifications',
  sessionSearch: 'sessionSearch',
  smsSignup: 'smsSignup',
  speakerQuestionModerator: 'speakerQuestionModerator',
  speakerSearch: 'speakerSearch',
  sponsorSearch: 'sponsorSearch',
  tournament: 'tournament',
  //TODO: remove "villageMedical" specific words from codebase
  villageMedicalPlans: 'villageMedicalPlans',
  villageMedicalProviders: 'villageMedicalProviders',
  votingAdmin: 'votingAdmin',
  votingView: 'votingView',
}

export const EXCON_NAMES = {
  adminSupportMenu: 'Admin/Support Excon Menu',
  attendeeList: 'Attendee List',
  badge: 'Badge',
  byod: 'BYOD Verification',
  byodStatus: 'BYOD Status',
  calendarAdmin: 'Calendar Admin',
  calendarView: 'Calendar',
  contentForm: 'Content Form',
  customerAdmin: 'Customer Admin',
  displayContent: 'Display Content',
  eventAppMenu: 'Event App Menu',
  eventFeedback: 'Event Feedback',
  eventFeedbackAdmin: 'Event Feedback (Admin)',
  exhibitorSearch: 'Exhibitor Search',
  externalLink: 'External Link',
  fileUploadTool: 'File Upload Manager',
  form: 'Form',
  glossary: 'Glossary',
  healthScreening: 'Health Screening',
  healthScreeningManager: 'Health Screening Manager',
  interactiveForm: 'Interactive Form',
  interactiveFormAdmin: 'Interactive Form Admin',
  inventory: 'Inventory',
  itinerary: 'Itinerary',
  listForm: 'List Form',
  lsd: 'List Search Details (LSD)',
  manageProfile: 'Manage Profile',
  meetings: 'Meetings',
  notifications: 'Send Notification',
  oshHospitals: 'OSH Hospitals',
  oshPlans: 'OSH Plans',
  oshProviders: 'OSH Providers',
  partsInventory: 'Parts Inventory',
  poll: 'Poll',
  pollAdmin: 'Poll (admin)',
  profileMatch: 'Profile Match',
  quote: 'CUSTOM_QUOTE',
  roster: 'Roster',
  schedule: 'Schedule',
  sendDiscoveryNotifications: 'Send Discovery Notifications',
  sessionSearch: 'Session Search',
  smsSignup: 'SMS Signup',
  speakerQuestionModerator: 'Speaker question moderator',
  speakerSearch: 'Speaker Search',
  sponsorSearch: 'Sponsor Search',
  tournament: 'Tournament',
  //TODO: remove "villageMedical" specific words from codebase
  villageMedicalPlans: 'Plans',
  villageMedicalProviders: 'Providers',
  votingAdmin: 'Voting Admin',
  votingView: 'Voting',
}

export const EXCON_HOSTS = {
  excon_place: 'excon_place',
}
