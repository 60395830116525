/**
 * worker executes appSettings documents based on the registrationSystem constant in processIntegration.js
 * for us to keep using worker's current functionality we need our connector providers
 * to be directly mapped to registrationSystems
 */

export const CONNECTOR_PROVIDERS = {
  aventri: 'aventri',
  box: 'box',
  cds: 'cdsbridge',
  cvent: 'cvent',
  eShow: 'eShow',
  eventRebels: 'eventRebels',
  rainfocus: 'rainfocus',
  siliconSlopes: 'siliconSlopes',
  streamPoint: 'streamPoint',
  scheduleItems: 'scheduleItems',
  sessionBoard: 'sessionBoard',
  swapCard: 'swapCard',
} as const

export const CONNECTOR_TYPES = {
  meetings: 'meetings',
  exhibitors: 'exhibitors',
  participants: 'participants',
  scheduleItems: 'scheduleItems',
  sessions: 'sessions',
  speakers: 'speakers',
  sponsors: 'sponsors',
} as const

export const CONNECTOR_ASSOCIATIONS = {
  [CONNECTOR_PROVIDERS.aventri]: [
    CONNECTOR_TYPES.participants,
    CONNECTOR_TYPES.sessions,
    CONNECTOR_TYPES.speakers,
    CONNECTOR_TYPES.scheduleItems,
  ],
  [CONNECTOR_PROVIDERS.box]: [CONNECTOR_TYPES.participants],
  [CONNECTOR_PROVIDERS.cds]: [CONNECTOR_TYPES.participants],
  [CONNECTOR_PROVIDERS.cvent]: [
    CONNECTOR_TYPES.participants,
    CONNECTOR_TYPES.sessions,
    CONNECTOR_TYPES.speakers,
    CONNECTOR_TYPES.scheduleItems,
  ],
  [CONNECTOR_PROVIDERS.eShow]: [CONNECTOR_TYPES.participants],
  [CONNECTOR_PROVIDERS.eventRebels]: [CONNECTOR_TYPES.participants],
  [CONNECTOR_PROVIDERS.rainfocus]: [CONNECTOR_TYPES.participants],
  [CONNECTOR_PROVIDERS.sessionBoard]: [
    [CONNECTOR_TYPES.sessions],
    [CONNECTOR_TYPES.speakers],
    [CONNECTOR_TYPES.scheduleItems],
  ],
  [CONNECTOR_PROVIDERS.siliconSlopes]: [
    [CONNECTOR_TYPES.participants],
    [CONNECTOR_TYPES.sessions],
    [CONNECTOR_TYPES.speakers],
  ],
  [CONNECTOR_PROVIDERS.streamPoint]: [CONNECTOR_TYPES.participants],
  [CONNECTOR_PROVIDERS.swapCard]: [CONNECTOR_TYPES.participants],
} as const

export const CONNECTOR_STATUS = {
  active: 'active',
  stopped: 'stopped',
}
