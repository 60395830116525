import React from 'react'
import PropTypes from 'prop-types'
import { Flex } from 'mui-blox'
import { IconButton, Paper } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import CloseIcon from 'mdi-react/CloseIcon'
import { useHighZoomDetection } from '../hooks/useHighZoomDetection'

const useStyles = makeStyles(({ palette, spacing, mixins: { alpha } }) => ({
  messengerContainer: props => ({
    '& > div': {
      display: 'flex',
      flex: 1,
      height: '100%',
      minHeight: props.isVeryHighZoom ? '100%' : 0,
      overflowY: props.isVeryHighZoom ? 0 : 'auto',
    },
  }),
  paper: {
    backgroundColor: palette.background.default,
    borderRadius: spacing(0.5),
  },
  closeButton: props => ({
    position: 'absolute',
    top: props.isVeryHighZoom ? 0 : 6,
    right: 6,
    width: 32,
    height: 32,
    minHeight: 32,
    backgroundColor: alpha(palette.secondary.light, 0.5),
    color: palette.secondary.contrastText,
    zIndex: props.isVeryHighZoom ? 1200 : 'auto',
  }),
}))

const PopupMessengerContainer = ({ children, onClose, showClose }) => {
  const { isVeryHighZoom } = useHighZoomDetection(4)

  const classes = useStyles({ isVeryHighZoom })

  const handleClose = () => {
    if (onClose) {
      onClose()
    }
  }

  const conditionalMinHeight = isVeryHighZoom ? '100%' : 0

  return (
    <Flex flexColumn height='100%' minHeight={conditionalMinHeight} className={classes.messengerContainer}>
      <Paper elevation={3} square={false} className={classes.paper}>
        {children}
      </Paper>
      {showClose && (
        <IconButton
          onClick={handleClose}
          className={classes.closeButton}
          size='small'
          aria-label='Close chat'
          tabIndex={-1}
        >
          <CloseIcon />
        </IconButton>
      )}
    </Flex>
  )
}

PopupMessengerContainer.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func,
  showClose: PropTypes.bool.isRequired,
}

PopupMessengerContainer.defaultProps = {
  onClose: undefined,
}

export default PopupMessengerContainer
