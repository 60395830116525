import { useCallback, useState } from 'react'

export const useRequest = <TPayload, TResponse>(url: string, method: 'GET' | 'POST' | 'PATCH' | 'DELETE') => {
  const [isRequesting, setIsRequesting] = useState(false)

  const request = useCallback(
    async (payload?: TPayload): Promise<TResponse> => {
      try {
        setIsRequesting(true)
        const response = await fetch(url, {
          method,
          body: JSON.stringify(payload),
          credentials: 'same-origin',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
          },
        })

        if (response.status === 204) {
          return undefined as unknown as TResponse
        }
        if (response.status === 200) {
          const data = await response.json()

          return data as TResponse
        }

        throw new Error(`${response.status}|${response.statusText}`)
      } finally {
        setIsRequesting(false)
      }
    },
    [url, method],
  )

  return {
    request,
    isRequesting,
  }
}
