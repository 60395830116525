import { useRequest } from '~brix/connectors/fetch'

export type PlatformRequestEndpoint =
  | 'auth-getPlatformAuthToken'
  | 'https-getExconSettings'
  | 'https-getEventSettings'

export const usePlatformRequest = <TPayload, TResponse = undefined>(
  name: PlatformRequestEndpoint,
  method: 'GET' | 'POST' | 'PATCH' | 'DELETE',
  pathParams?: string,
) => {
  const cloudUrl = process.env.REACT_APP_EXCON_PLACE_FUNCTIONS
  const endpoint = pathParams ? `${name}/${pathParams}` : name
  const { request, isRequesting } = useRequest<TPayload, TResponse>(`${cloudUrl}/${endpoint}`, method)

  return {
    request,
    isRequesting,
  }
}
