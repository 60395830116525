import { useCallback, useState } from 'react'
import { generateNanoId } from '../../../../core/util/generateNanoId'
import { useQueryParams } from '../../routing/hooks/useQueryParams'
import { ConversationIdArgs } from '../types'

const CONVERSATION_KEY = 'conversation_id'

export const useConversationId = (eventId: string): ConversationIdArgs => {
  const { conversationId: queryId } = useQueryParams()

  const initConversationId = useCallback(() => {
    const storedId = localStorage.getItem(getKey(eventId))

    if (queryId && queryId !== storedId) {
      if (isInvalidConversationId(queryId))
        throw Error(`Invalid conversationId ${queryId}. It must be alphanumeric.`)
      localStorage.setItem(getKey(eventId), queryId)

      return queryId
    } else if (!storedId || storedId === 'undefined' || (storedId && isInvalidConversationId(storedId))) {
      const generated = generateNanoId(12, 'abcdefghijklmnopqrstufwxyz0123456789')

      localStorage.setItem(getKey(eventId), generated)

      return generated
    }

    return storedId
  }, [eventId, queryId])

  const [conversationId, setConversationId] = useState<string | null>(initConversationId)

  const resetId = () => {
    localStorage.removeItem(getKey(eventId))
    setConversationId(null)
  }

  return {
    conversationId,
    resetId,
  } as ConversationIdArgs
}

const getKey = eventId => {
  return `${CONVERSATION_KEY}-${eventId}`
}

const isInvalidConversationId = id => /\W/.test(id)
