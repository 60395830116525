import { useEffect, useState, useRef } from 'react'

interface HighZoomResult {
  isVeryHighZoom: boolean
  zoomRatio: number
}

export const useHighZoomDetection = (threshold = 4): HighZoomResult => {
  const isDesktop = typeof navigator !== 'undefined' && /Win|Mac|Linux/i.test(navigator.platform ?? '')

  const [result, setResult] = useState<HighZoomResult>({ isVeryHighZoom: false, zoomRatio: 1 })
  const baselineDPRRef = useRef<number>(typeof window !== 'undefined' ? window.devicePixelRatio || 1 : 1)

  useEffect(() => {
    if (!isDesktop || typeof window === 'undefined') return

    const mm = window.matchMedia('(min-resolution: 384dpi)')

    let resizeTimeout: number | undefined

    const checkZoom = (): void => {
      const currentDPR = window.devicePixelRatio || 1
      const zoomRatio = currentDPR / baselineDPRRef.current
      const dpiQueryMatches = mm.matches
      const isVeryHighZoom = zoomRatio >= threshold || dpiQueryMatches

      setResult({ isVeryHighZoom, zoomRatio })
    }

    const handleResize = (): void => {
      if (resizeTimeout) {
        clearTimeout(resizeTimeout)
      }
      resizeTimeout = window.setTimeout(() => {
        checkZoom()
      }, 150)
    }

    checkZoom()
    mm.addEventListener('change', checkZoom)
    window.addEventListener('resize', handleResize, { passive: true })

    return () => {
      mm.removeEventListener('change', checkZoom)
      window.removeEventListener('resize', handleResize)
      if (resizeTimeout) clearTimeout(resizeTimeout)
    }
  }, [threshold, isDesktop])

  return result
}
