import React from 'react'
import { AppBar, Avatar, Stack, Typography, Slide, useScrollTrigger } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import ClassNames from 'classnames'
import { useEventBranding } from '~brix/connectors/context/useEventBranding'
import { useHighZoomDetection } from '../hooks/useHighZoomDetection'

const HideOnScroll = ({ children }) => {
  const trigger = useScrollTrigger({})

  return (
    <Slide appear={false} direction='down' in={!trigger}>
      {children}
    </Slide>
  )
}

const Header = () => {
  const { isVeryHighZoom } = useHighZoomDetection(4)

  const classes = useStyles({ isVeryHighZoom })
  const { branding } = useEventBranding()

  if (!isVeryHighZoom) {
    return (
      <AppBar
        elevation={0}
        className={classes.root}
        id='messenger-header'
        tabIndex={-1}
        role='banner'
        aria-labelledby='messenger-botname'
      >
        <Stack alignItems='center' direction='row' justifyContent='space-between' className={classes.stackPadding}>
          <Stack direction='row' alignItems='center'>
            <Avatar
              src={branding.display.businessIconUrl}
              alt='Bot Avatar'
              className={ClassNames({
                [classes.avatarHighZoom]: isVeryHighZoom,
              })}
              sx={{ mr: 1 }}
            />
            <Typography variant='h2' className={classes.title} id='messenger-botname'>
              {branding.botName}
            </Typography>
          </Stack>
          <Stack alignItems='inherit' color='white' direction='row' pl={1} />
        </Stack>
      </AppBar>
    )
  }

  return (
    <HideOnScroll>
      <AppBar
        elevation={0}
        className={classes.root}
        id='messenger-header'
        tabIndex={-1}
        role='banner'
        aria-labelledby='messenger-botname'
      >
        <Stack alignItems='center' direction='row' justifyContent='space-between' className={classes.stackPadding}>
          <Stack direction='row' alignItems='center'>
            <Avatar
              src={branding.display.businessIconUrl}
              alt='Bot Avatar'
              className={ClassNames({
                [classes.avatarHighZoom]: isVeryHighZoom,
              })}
              sx={{ mr: 1 }}
            />
            <Typography variant='h2' className={classes.title} id='messenger-botname'>
              {branding.botName}
            </Typography>
          </Stack>
          <Stack alignItems='inherit' color='white' direction='row' pl={1} />
        </Stack>
      </AppBar>
    </HideOnScroll>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: theme.spacing(0.5, 0.5, 0, 0),
  },
  stackPadding: props => ({
    padding: props.isVeryHighZoom ? theme.spacing(0.5) : theme.spacing(1),
  }),
  title: {
    color: theme.palette.primary.contrastText,
    fontSize: '1.25rem',
    fontWeight: 400,
    lineHeight: 1.3,
  },
  avatarHighZoom: props => ({
    width: props.isVeryHighZoom ? theme.spacing(2) : theme.spacing(4),
    height: props.isVeryHighZoom ? theme.spacing(2) : theme.spacing(4),
  }),
}))

export default Header
