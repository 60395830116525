import { POWER_UP_KEYS } from './powerUps'
import { integrations } from './integrations'

export { PARTICIPANT_FIELD_PRIVACY, PARTICIPANT_FIELD_PRIVACY_LABELS } from './participantFieldPrivacy'

export {
  LEAD_STATUS as HUBSPOT_LEAD_STATUS,
  PIPELINES as HUBSPOT_PIPELINES,
  DEAL_TYPE as HUBSPOT_DEAL_TYPE,
} from './hubspot'

export { aventriTempDocTypes } from './aventri'
export { botResponseActions } from './botResponseActions'
export { cventTempDocTypes } from './cvent'
export { expocadTempDocTypes } from './expocad'
export { featureFlags, featureFlagCategories } from './featureFlags'
export { idkStatus } from './idkStatus'
export { mciTempDocTypes } from './mci'
export { nlpProviders } from './nlpProviders'
export { planstoneTempDocTypes } from './planstone'
export { salesForceTempDocTypes } from './salesForce'
export { streampointTempDocTypes } from './streampoint'
export { systemEntities } from './systemEntities'

export * from './api'
export * from './assets'
export * from './billing'
export * from './blocks'
export * from './botComponents'
export * from './botMode'
export * from './botReview'
export * from './calendar'
export * from './connectionStati'
export * from './connectors'
export * from './conversation'
export * from './customKeyTypes'
export * from './cxp'
export * from './demoTypes'
export * from './deploy'
export * from './exconKeys'
export * from './feedback'
export * from './flatfile'
export * from './forms'
export * from './healthScreening'
export * from './hubspotDestinations'
export * from './integrations'
export * from './interactiveForm'
export * from './jobQueue'
export * from './language'
export * from './library'
export * from './messageBubbleTypes'
export * from './messages'
export * from './messenger'
export * from './metaData'
export * from './mindbody'
export * from './nlpStates'
export * from './notifications'
export * from './orchestrationResponseActions'
export * from './osh'
export * from './participant'
export * from './participantTypes'
export * from './phoneNumbers'
export * from './phoneTypes'
export * from './powerUps'
export * from './questions'
export * from './shortLinks'
export * from './socket'
export * from './sorting'
export * from './states'
export * from './surveyBlockTypes'
export * from './telnyx'
export * from './trainingPhraseShortCircuit'
export * from './userSettings'
export * from './validatorTypes'
export * from './wellKnownVariables'
export * from './customForms'
export * from './regex'

export const COMPANY_TITLE = '42Chat'

export const logEventTypes = {
  apiAi: 'APIAI',
  botMessage: 'botMessage',
  humanSupportRequest: 'humanSupportRequest',
  idk: 'IDK',
  newUser: 'newUser',
  privateSupportRequested: 'privateSupportRequested',
  resubscribeRequested: 'resubscribeRequested',
  supportConversationStart: 'supportConversationStart',
  startRequested: 'startRequested',
  stopRequested: 'stopRequested',
  unsubscribedUserMessage: 'unsubscribedUserMessage',
  unsubscribeRequested: 'unsubscribeRequested',
  userMessage: 'userMessage',
}

export const rabbitExchangeNames = {
  sciensioDirect: 'sciensio.direct',
}

export const rabbitQueueNames = {
  sciensioWorker: 'sciensio.worker',
}

// Bind the routing key on the 'sciensio-direct' exchange to the sciensio-worker queue
export const rabbitRoutingKeys = {
  sciensioWorker: 'sciensio-worker',
}

export const registrationSystems = {
  aventri: 'aventri',
  bizzabo: 'bizzabo',
  boomset: 'boomset',
  box: 'box',
  bravura: 'bravura',
  cdsBridge: 'cdsbridge',
  completeReg: 'completeReg',
  compuSystems: 'compuSystems',
  confex: 'confex',
  connectspace: 'connectspace',
  customImport: 'customImport',
  cvent: 'cvent',
  edgereg: 'edgereg',
  eShow: 'eShow',
  ev2: 'ev2',
  eventbrite: 'eventbrite',
  eventLeap: 'eventLeap',
  eventRebels: 'eventRebels',
  eventsquid: 'eventsquid',
  experient: 'experient',
  externalForm: 'externalForm',
  goExpo: 'goExpo',
  impexium: 'impexium',
  informaEV2: 'informaEV2',
  itn: 'itn',
  klik: 'klik',
  manual: 'manual',
  mci: 'mci',
  nacdsPersonify: 'nacdsPersonify',
  omnicore: 'omnicore',
  pheedLoop: 'pheedLoop',
  rainfocus: 'rainfocus',
  rcs: 'rcs',
  regFox: 'regfox',
  rxGlobalAtlas: 'rxGlobalAtlas',
  salesForce: 'salesForce',
  selfRegister: 'self-register',
  sessionBoard: 'sessionBoard',
  siliconSlopes: 'siliconSlopes',
  streampoint: 'streampoint',
  swapCard: 'swapCard',
  swoogo: 'swoogo',
  ticketSauce: 'ticketSauce',
  webRegistration: 'web-registration',
} as const

/**
 * @deprecated
 * start replacing with connectors defs
 */
export const exhibitorSourceSystems = {
  a2zinc: 'a2zinc',
  aventri: 'aventri',
  bizzabo: 'bizzabo',
  customImport: 'custom-import',
  expocad: 'expocad',
  fileImport: 'file-import',
  manual: 'manual',
  mapYourShow: 'mapYourShow',
  modex: 'modex',
  myWorldOfExpo: 'myWorldOfExpo',
  nacdsPersonify: 'nacdsPersonify',
  siliconSlopes: 'siliconSlopes',
}

/**
 * @deprecated
 * start replacing with connectors defs
 */
export const scheduleItemSourceSystems = {
  fileImport: 'file-import',
  manual: 'manual',
}

/**
 * @deprecated
 * start replacing with connectors defs
 */
export const sessionSourceSystems = {
  a2zinc: 'a2zinc',
  aventri: 'aventri',
  bizzabo: 'bizzabo',
  cadmium: 'cadmium',
  eventRebels: 'eventRebels',
  fileImport: 'file-import',
  itn: 'itn',
  manual: 'manual',
  planstone: 'planstone',
  siliconSlopes: 'siliconSlopes',
}

/**
 * @deprecated
 * start replacing with connectors defs
 */
export const sponsorSourceSystems = {
  fileImport: 'file-import',
  manual: 'manual',
}

/**
 * @deprecated
 * start replacing with connectors defs
 */
export const meetingSourceSystems = {
  fileImport: 'file-import',
  issi: 'issi',
  manual: 'manual',
  nacdsPersonify: 'nacdsPersonify',
}

export const rabbitMessageTypes = {
  a2zincSync: 'a2zinc.sync',
  cleanDialogFlowAgentForEvent: 'cleanDialogFlowAgentForEvent',
  cleanIntakeStaging: 'cleanIntakeStaging',
  decommissionBot: 'bot.decommission',
  deactivateDemoBot: 'demobot.deactivate',
  deployBot: 'deployBot',
  dynamicEntity: 'entity.dynamic',
  generateBotStatistics: 'bot.generatestatistics',
  importParticipants: 'participants.import',
  importFile: 'file.import',
  issiSync: 'issi.sync',
  itnSessionSync: 'itn.session.sync',
  processNotification: 'notification.process',
  provisionBotFromTemplate: 'bot.provisionfromtemplate',
  provisionNewBot: 'bot.provision',
  pullConversationFromDialogFlow: 'pullConversationFromDialogFlow',
  pushEventToDialogFlow: 'pushEventToDialogFlow',
  runPreviousDayBilling: 'runPreviousDayBilling',
  scheduledNotification: 'scheduledNotification',
  sendDailyIdkDigest: 'sendDailyIdkDigest',
  syncCadmium: 'cadmium.syncAllEvents',
  syncAventriSessions: 'aventri.syncSessions',
  syncExhibitors: 'exhibitors.sync',
  syncExpocad: 'expocad.sync',
  syncIntents: 'intents.sync',
  syncMapYourShow: 'mapYourShow.sync',
  syncParticipants: 'participants.sync',
  syncPhoneNumbers: 'phoneNumbers.sync',
  syncPlanstone: 'planstone.syncAllEvents',
  syncSessions: 'sessions.sync',
  syncTrafficController: 'trafficController.sync',
  trainIntents: 'intents.train',
  scheduleRecurringNotifications: 'schedule.recurring.notifications',
}

export const twilioAreaCodeWildcard = '***'

export const trafficController = {
  eventName: 'TrafficController',
  intentActionName: 'matchEvent',
}

export const cacheKeys = {
  smoochCache: 'smoochCache',
  appEventsCache: 'appEventsCache',
  dfAgentClientCache: 'dfAgentClientCache',
  dfContextClientCache: 'dfContextClientCache',
  dfEntityClientCache: 'dfEntityClientCache',
  dfIntentClientCache: 'dfIntentClientCache',
  dfSessionClientCache: 'dfSessionClientCache',
  twilioCache: 'twilioCache',
}

export const cacheExpiration = {
  appEvents: process.env.SETTINGS_CACHE_DURATION || 600, //default of 10 minutes
  smoochCache: 3600, // 1 hour cache.
}

export const messageRoles = {
  appMaker: 'appMaker',
  appUser: 'appUser',
}

export const userPlatforms = {
  messenger: 'messenger',
  twitter: 'twitter',
  twilio: 'twilio',
  web: 'web',
  whatsApp: 'whatsapp',
  telnyx: 'telnyx',
  zipwhip: 'zipwhip',
}

export const bubbleSeparators = {
  current: '\\$',
  legacy: '\\n',
}

export const productTypes = {
  bot: 'bot',
  addOn: 'addOn',
  passThrough: 'passThrough',
  upgrade: 'upgrade',
  fullService: 'fullService',
}

export const provisioningStages = {
  queued: 'queued',
  started: 'started',
  completed: 'completed',
  failed: 'failed',
}

export const eventSettingsKeys = {
  ...integrations,
  aiSettings: 'aiSettings',
  auction: 'auction',
  botMenu: 'botMenu',
  branding: 'branding',
  buttonTranslations: 'buttonTranslations',
  carousel: 'carousel',
  chatbase: 'chatbase',
  chatbotBranding: 'chatbotBranding',
  connector: 'connector',
  customerDelivery: 'customerDelivery',
  deliveryStatusContent: 'deliveryStatusContent',
  email: 'email',
  excon: 'excon',
  featureFlags: 'featureFlags',
  hashtags: 'hashtags',
  indexMenu: 'indexMenu',
  intuitAuthorization: 'intuitAuthorization',
  itnSessions: 'itnSessions',
  liveChat: 'liveChat',
  messenger: 'messenger',
  metricRanges: 'metricsRanges',
  participantFieldsPowerUp: 'participantFieldsPowerUp',
  calendarPowerUp: 'calendarPowerUp',
  connectedStatusPowerUp: 'connectedStatusPowerUp',
  inventoryPowerUp: 'inventoryPowerUp',
  participantImport: 'participantImport',
  preprocessor: 'preprocessor',
  pushMessages: 'pushMessages',
  registrationSystem: 'registrationSystem',
  roster: 'roster',
  scheduledTask: 'scheduledTask',
  slack: 'slack',
  smooch: 'smooch',
  support: 'support',
  telnyx: 'telnyx',
  twilio: 'twilio',
  webMessenger: 'webMessenger',
  yakIntents: 'yakIntents',
  zipwhip: 'zipwhip',
  rxGlobalAtlas: 'rxGlobalAtlas',
}

export const powerUpEventSettingsKeys = {
  [POWER_UP_KEYS.botMenu]: eventSettingsKeys.botMenu,
  [POWER_UP_KEYS.calendar]: eventSettingsKeys.calendarPowerUp,
  [POWER_UP_KEYS.connectedStatus]: eventSettingsKeys.connectedStatusPowerUp,
  [POWER_UP_KEYS.clientInventory]: eventSettingsKeys.inventoryPowerUp,
  [POWER_UP_KEYS.participantFields]: eventSettingsKeys.participantFieldsPowerUp,
}

export const BRANDING_KEYS = {
  logoUrl: 'logoUrl',
  theme: 'theme',
  title: 'title',
}

export const CSV_UPLOAD_TYPES = {
  participants: 'participants',
  exhibitors: 'exhibitors',
  scheduleItems: 'scheduleItems',
  sessions: 'sessions',
  speakers: 'speakers',
  sponsors: 'sponsors',
  tournaments: 'tournaments',
}

/** @deprecated */
export const notificationMessageTypes = {
  adHoc: 'adHoc',
  callToAction: 'Call To Action',
  eventGreeting: 'Event Greeting',
  followUp: 'Follow Up',
  lateRegistrationConfirmation: 'Late Registration Confirmation',
  other: 'Other',
  participantImportFollowUp: 'Participant Import Follow Up',
  registrationConfirmation: 'Registration Confirmation',
  template: 'Template',
}

export const asyncMessageStatus = {
  completed: 'completed',
  failed: 'failed',
  started: 'started',
  resumed: 'resumed',
}

export enum ReservationStatus {
  Available = 'available',
  Busy = 'busy',
  TakenByCurrentUser = 'taken-by-current-user',
}

export const DELETE_MANY_PARTICIPANTS_QUERY_TYPE = {
  filter: 'filter',
  participantIdsList: 'participantIdsList',
}

export const SYNONYM_BACKLISTED_WORDS = [
  ', Inc.',
  ',Inc.',
  '.Inc',
  'Inc.',
  'CORP.',
  ',LLC',
  ', LLC',
  '.LLC',
  '. LLC',
  'LLC',
  'CO.',
  ',LTD.',
  ', LTD.',
  'LTD.',
  '®',
  'Intl',
  'Stl',
]
