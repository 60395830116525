import { combineUrl } from '~core/util/string/combineUrl'
import { sciensio as config } from '../../../config'

export const getApiUrl = (url: string, byPassCombine = false) => {
  if (byPassCombine) {
    return url
  }

  return combineUrl(config.apiUrl, 'api', url)
}
