import React from 'react'
import { createRoot } from 'react-dom/client'
import flagsmith from 'flagsmith'
import { FlagsmithProvider } from 'flagsmith/react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import AppRoot from './AppRoot'
import { SocketSpacesProvider } from './react/socketv4/SocketSpacesProvider'
import { StylesProvider } from './style'

import '../../node_modules/react-virtualized/styles.css'
import { MessengerRoot } from './react/messenger'
import { CustomFormsRoot } from './react/custom-forms/CustomFormsRoot'
import { config } from '~config'

const container = document.getElementById('root')

if (container) {
  const root = createRoot(container)

  root.render(
    <FlagsmithProvider
      options={{ identity: 'anonymous', environmentID: config.flagsmith.environmentID as string }}
      flagsmith={flagsmith}
    >
      <BrowserRouter>
        <SocketSpacesProvider>
          <StylesProvider>
            <Switch>
              <Route path='/chatbot' component={MessengerRoot} />
              <Route path='/custom-forms' component={CustomFormsRoot} />
              <Route path='/' component={AppRoot} />
              <Redirect to='/' />
            </Switch>
          </StylesProvider>
        </SocketSpacesProvider>
      </BrowserRouter>
    </FlagsmithProvider>,
  )
}
