// TODO : replace usage of useExconSettingsContext with this
import { useEffect } from 'react'
import { Map, fromJS } from 'immutable'
import { atom, selectorFamily, useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from 'recoil'
import { useRouteMatch } from 'react-router-dom'
import { simpleFetch } from '../../../lib/simpleFetch'

const overrideState = atom({
  key: 'settingsOverrideState/Atom',
  default: null,
})

//TODO: change this to fetch from cloud function
const exconSettingsState = selectorFamily({
  key: 'exconSettingsState/SelectorFamily',
  get: exconId => async () => {
    if (exconId === null) return Map()
    const response = await simpleFetch.get(`/excon/settings/${exconId}`)
    return fromJS(response)
  },
})

const useOverrideValue = () => useRecoilValue(overrideState)
const useSetOverrideValue = () => useSetRecoilState(overrideState)

export const useExconSettings = (options = {}) => {
  const { settings } = options
  const match = useRouteMatch('/excon/:exconId')

  const override = useOverrideValue()
  const setOverride = useSetOverrideValue()

  useEffect(() => {
    if (settings && !override) {
      setOverride(settings)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Boolean(settings), override, setOverride, settings])

  const fetchId = (settings || override ? null : match?.params?.exconId) || null

  const loadable = useRecoilValueLoadable(exconSettingsState(fetchId))
  const data = fetchId ? (loadable.state === 'hasValue' ? loadable.contents : Map()) : override

  return {
    data,
    isLoading: fetchId ? loadable.state === 'loading' : data == null,
  }
}
